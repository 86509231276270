<template>
    <div id="memberOrderConfirmPage">
      <div class="container">
        <div class="box box-1">
          <div class="thumb">
            <van-image class="img" :src="detail.avatarTemp" fit="contain"></van-image>
          </div>
          <div class="right">
            <div class="top">
              <div class="title text-ellipsis">{{ detail.gradesName }}</div>
              <div class="desrc">ユーザーランク：{{ detail.gradesName }}</div>
              <div class="desrc">サブスクリプション期間：{{ detail.day }}日間</div>
            </div>
            <div class="bottom">
              <span class="price">
                <span class="big">{{detail.sellingPrice}}</span>JPY
              </span>
              <span class="del">{{detail.originalCost}}JPY</span>
            </div>
          </div>
        </div>
        <div class="box box-2">
          <div class="left">合計金額</div>
          <div class="right">
            <span class="price">
              <span class="big">{{detail.sellingPrice}}</span>JPY
            </span>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="submit-btn" :class="{'gary':!isSubmit}" @click="onSubmit">支払</div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'MemberOrderConfirm',
  components: {},
  data () {
    return {
      type: '',
      id: '',
      detail: {},
      isSubmit: true,
      isBind: false
    };
  },
  created () {
  },
  mounted () {
    const { $route: { meta, query: { id, type } } } = this;
    this.id = id;
    this.type = type;

    switch (type) {
      case '0':
        meta.title = '会員購読';
        break;
      case '1':
      case '2':
        meta.title = '会員ランク変更';
        break;
    }
    this.isBind = this.personInfo.crediCardState;

    this.getDetail();
  },
  watch: {},
  computed: {
    ...mapGetters(['personInfo'])
  },
  methods: {

    /* 获取购买确认礼包页 */
    getDetail () {
      const { id } = this;
      this.$http.get('mine/affirmBuy', {
        params: {
          giftId: id
        }
      }).then((res) => {
        this.detail = res.data || {};
      });
    },
    onSubmit () {
      // this.$dialog.confirm({
      //   title: 'ご注意',
      //   message: '支払は完了できません（残額足りない、期限切など）、再度クレジットカードの情報を入力してください',
      //   confirmButtonText: '更新',
      //   cancelButtonText: 'キャンセル',
      //   closeOnClickOverlay: true
      // }).then(() => {

      // }).catch(() => {});
      if (!this.isSubmit) {
        return false;
      }
      const { id, type } = this;
      let api = '';
      let obj = '';
      let payWay = '';
      switch (type) {
        case '':
        case '1':
          api = 'mine/buyMember';
          payWay = 'post';
          obj = [{ giftId: parseInt(id) }, { showLoading: true }];
          break;
        case '2':
          api = 'mine/changeReservation';
          payWay = 'get';
          obj = [{ params: { giftId: parseInt(id) }, showLoading: true }];
          break;
      }
      if (type === '1' || type === '2') {
        if (this.isBind === 2) {
          this.$utils.toast({
            message: 'お客様のクレジットカードが期限切れになりました。クレジットカードの情報を更新してください。',
            onClose: () => {
              this.$router.push({ name: 'SettingIndex', params: { type: 1 } });
            }
          });
          return false;
        }
      }
      this.$http[payWay](api, ...obj).then(async (res) => {
        const elepay = await this.loadElepay('pk_live_90f822a7a681e4670c3d3');
        let data, orderNo;
        this.isSubmit = false;
        // if (!this.isSubmit) {
        //   this.$utils.toast('ローディング...');
        // }
        let user;
        if (type === '2') {
          user = res.data;
          data = res.data.elepayInfo;
          orderNo = res.data.elepayInfo.orderNo;
        } else {
          data = res.data;
          orderNo = res.data.orderNo;
        }
        elepay.handleCharge(data).then((result) => {
        // ① 正常処理
          if (result.type === 'cancel') {
            // 取消
          } else if (result.type === 'success') {
            this.timeSuccess(orderNo, user);
            // 成功
          }
        }).catch((err) => {
          console.log(err);
        });
      });

      // if (this.type === '2') {
      //   this.$http.get('mine/changeReservation', {
      //     params: {
      //       activityId: parseInt(id)
      //     }
      //   }).then((res) => {
      //     $router.push({
      //       name: 'MemberSuccess',
      //       query: {
      //         user: res.data,
      //         type: this.type
      //       }
      //     });
      //   });
      // }

    },

    /* 时间轮询 */
    timeSuccess (orderNo, user) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$http.get('mine/orderStatus', { params: { orderNo }, showLoading: true }).then((res) => {
          this.type === '' ? this.$router.replace({ name: 'MemberIndex' }) : this.$router.replace({ name: 'MemberSuccess', query: { type: this.type, user } });
        }).catch(() => {
          this.timeSuccess(orderNo);
          this.isSubmit = true;
        });
      }, 1000);
    }

  /*   onSubmit () {
      const { id } = this;
      this.$http.post('mine/buyMember', {
        activityId: parseInt(id)
      }).then(async (res) => {
        let { $router } = this;
        const elepay = await this.loadElepay('pk_live_90f822a7a681e4670c3d3', { 'locale': 'zh-CN' });
        elepay.handleCharge(res.data)
          .then((result) => {
            if (result.type === 'cancel') {
              console.log('异常');
            } else if (result.type === 'success') {
              $router.push({
                name: 'MemberSuccess',
                query: {
                  user: res.data,
                  type: this.type
                }
              });
            }
          })
          .catch((err) => {
            console.log('支付异常:', err);
          });
      });

      if (this.type === '2') {
        this.$http.get('mine/changeReservation', {
          params: {
            activityId: parseInt(id)
          }
        }).then((res) => {
          let { $router } = this;
          $router.push({
            name: 'MemberSuccess',
            query: {
              user: res.data,
              type: this.type
            }
          });
        });
      }
    }
 */
  }
};
</script>

<style scoped lang="less">
#memberOrderConfirmPage {
  .container {
    .box {
      display: flex;
      background-color: #232324;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .price {
        margin-right: 24px;
        font-size: 24px;
        font-weight: bold;
        color: #ed544f;

        .big {
          font-size: 36px;
        }
      }

      &.box-1 {
        padding: 28px 22px;

        .thumb {
          flex-shrink: 0;
          width: 240px;
          height: 168px;
          margin-right: 32px;
          background-color: #2d2d2f;

          .img {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 16px;
          }
          // &.thumb-1 {
          //   background-image: url("~@/assets/images/bg_podium_01.png");
          // }
          // &.thumb-2 {
          //   background-image: url("~@/assets/images/bg_podium_02.png");
          // }
          // &.thumb-3 {
          //   background-image: url("~@/assets/images/bg_podium_03.png");
          // }
        }

        .right {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: space-between;

          .top {
            &:nth-child(n) {
              margin-bottom: 6px;
            }

            .title {
              font-size: 26px;
              line-height: 34px;
            }

            .desrc {
              font-size: 20px;
              line-height: 28px;
              opacity: 0.8;
            }
          }

          .bottom {
            margin-top: 20px;
            font-size: 24px;
          }
        }
      }

      &.box-2 {
        padding: 20px 28px;

        .left {
          flex: 1;
          font-size: 30px;
          line-height: 40px;
        }

        .right {
          flex-shrink: 0;
        }
      }
    }
  }

  .footer {
    padding: 28px;
    margin-top: 364px;
    // .gary{
    //   background: #CCCCCC;
    // }
  }
}
</style>